

@mixin font-face($family, $file, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url($path + "/" + $file);
    font-weight: $weight;
    font-style: $style;
  }
}


//  ---- Bree ----
// Normal
@include font-face('Bree', 'Bree-ExtraBold.otf', './fonts/Bree', 800, normal);
@include font-face('Bree', 'Bree-Bold.otf', './fonts/Bree', 700, normal);
@include font-face('Bree', 'Bree-Regular.otf', './fonts/Bree', 400, normal);
@include font-face('Bree', 'Bree-Light.otf', './fonts/Bree', 300, normal);
//Italic
@include font-face('Bree', 'Bree-ExtraBold-Italic.otf', './fonts/Bree', 800, italic);
@include font-face('Bree', 'Bree-Bold-Italic.otf', './fonts/Bree', 700, italic);
@include font-face('Bree', 'Bree-Italic.otf', './fonts/Bree', 400, italic);
@include font-face('Bree', 'Bree-Light-Italic.otf', './fonts/Bree', 300, italic);

.bree {
  font-family: 'Bree';
}


