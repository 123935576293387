

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}



// Weather bar
.valuesWB {
  font-size: 25px;
  line-height: 1.1;
  @include media-breakpoint-up("lg") {
    font-size: 45px;
    line-height: 1.2;
  }
}

.titlesWB {
  font-size: 10px;
  @include media-breakpoint-up("sm") {
    font-size: 15px;
  }
  @include media-breakpoint-up("lg") {
    font-size: 20px;
  }
}


// Section
.sectionBtnText {
  font-size:15px;
  line-height:1.1;
  font-weight:600;
  @include media-breakpoint-up("sm") {
    font-size:20px;
  }
  @include media-breakpoint-up("lg") {
    font-size:27px;
  }
}


// Header
.headerText {
  font-size: 16px;
  @include media-breakpoint-up("md") {
    font-size: 18px;
  }
  @include media-breakpoint-up("lg") {
    font-size: 22px;
  }
  @include media-breakpoint-up("xl") {
    font-size: 27px;
  }
}


//Back button
.backButton {
  cursor: pointer;
  z-index: 1;
  height: 50px;
  right: 10px;
  bottom: 75px;

  @include media-breakpoint-up("md") {
    height: 75px;
    right: 12px;
    bottom: 75px;
  }
  @include media-breakpoint-up("lg") {
    height: 100px;
    right: 15px;
    bottom: 150px;
  }
}


//Animations
.imageCrossfade {
  position: absolute;
  opacity: 1;
  transition: opacity 1.5s linear;
}